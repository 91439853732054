import React from 'react';
import SupportHero from '../components/supportPage/SupportHero.tsx';
import SendIssueButton from '../components/supportPage/SendIssueButton.tsx';
import HelpSuggestions from '../components/supportPage/HelpSuggestions.tsx';
import { Footer } from '../components/homepage';

export default function Support() {
  return (
    <div>
      <SupportHero />
      <SendIssueButton />
      <HelpSuggestions />
      <Footer />
    </div>
  );
}
