import React from 'react';
import './Button.css';
import {  useNavigate } from 'react-router-dom';

interface Buttonprops {
    text: string;
}

const Button = ({text}: Buttonprops) => {

  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      const target =  event.currentTarget
      const id = target.id
      navigate('/submit', {state: {catogery : id}})
      
  }

  return (
     <button className=' flex items-center justify-center lg:w-[351px] lg:h-[70px] sm:p-4 sm:w-[300px] sm:h-[50px] w-40 h-14  btn' id={text} onClick={handleClick}>
        <h1 className='font-RedHat lg:text-2xl sm:text-[16px] text-[.75rem] leading-9 text-[#FFFFFF]'>{text}</h1>
    </button>
  )
}

export default Button