import { useEffect, useState } from "react";
import "./style.css";

const data = [
  { title: "Community Members", value: "455,600" },
  { title: "Multilingual Communities", value: "17 +" },
  { title: "Community Ambassadors", value: "15 +" },
];

export default function Social() {
  const [isScrolled, setIsScrolled] = useState(false);
  const innerHeight = window.innerHeight;
  useEffect(() => {
    const socialContainer = document.querySelector(".lp");

    if (!isScrolled) {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= innerHeight * 5) {
          setIsScrolled(true);
          socialContainer.classList.add("active");
        }
      });
    } else {
      return () => {
        window.removeEventListener("scroll", arguments.callee);
      };
    }
  }, []);
  return (
    <section className="min-h-screen social-bg social pt-20">
      <div className="container mx-auto flex flex-col items-center">
        {/* Laptop with social icons */}
        <div className="laptop -mt-16">
          <div className="lp">
            <div className="child">
              <div className="camera">
                <div className="dot"></div>
              </div>
              <div className="icons-container">
                <div className="top-icons">
                  <a
                    href="https://www.linkedin.com/company/blazpay"
                    target="_blank"
                    className="linked"
                  >
                    {" "}
                    LinkedIn{" "}
                  </a>
                  <a
                    href="https://discord.com/invite/BlazPay"
                    target="_blank"
                    className="dicord"
                  >
                    {" "}
                    Discord{" "}
                  </a>
                </div>
                <div className="social-text">
                  <h1>join our community</h1>
                  <p>
                    Individually, we are one drop. Together, we are ocean. LETS
                    BUILD TOGETHER
                  </p>
                </div>
                <div className="bottom-icons">
                  <a
                    href="https://t.me/Blazpay"
                    target="_blank"
                    className="facebook"
                  >
                    Telegram
                  </a>
                  <a
                    href="https://twitter.com/blazpaylabs"
                    target="_blank"
                    className="instagram"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="lp-bottom"></div>
          <div className="lp-key">
            <div className="lp-key-child">
              <div className="key-center"></div>
            </div>
          </div>
        </div>

        {/* Laptop with social icons ends */}
        <div className="overflow-hidden container mx-auto  flex md:flex-row flex-col justify-between text-center md:mt-0 mt-10">
          {data.map((info) => {
            return (
              <div className="flex flex-col items-center font-bold mx-4">
                <p className="text-white lg:text-2xl text-xl  s-text">
                  {info.title}
                </p>
                <h1 className="text-[#ff7a1e] lg:text-3xl text-2xl md:my-5 mt-1 mb-6">
                  {info.value}
                </h1>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
