import React from 'react';
import SubmitHero from '../components/submitRequest/SubmitHero.tsx';
import RequestForm from '../components/submitRequest/RequestForm.tsx';
import { Footer } from '../components/homepage';

export default function Submit() {
  return (
    <div>
      <SubmitHero />
      <RequestForm />
      <Footer />
    </div>
  );
}
