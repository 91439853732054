import React from 'react';
import './SupportHero.css';

const SupportHero = () => {
  return (
    <div className='bg-img-support lg:h-[400px] sm:h-[300px] h-52 flex flex-col items-center justify-center lg:mt-10 '>
        <div className='lg:w-[904px] h-20 flex items-center justify-center leading-[80px] '>
            <h1 className='font-bold lg:text-[64px] lg:leading-[80px] sm:text-4xl text-2xl main-text font-Lexend  '>Hey! How can we Help you?</h1>
        </div>
    </div>
  )
}

export default SupportHero;