import React from 'react'
import Button from './Button.tsx';
import { Link } from 'react-router-dom';
import Vector from '../../assets/Vector.svg'
import Group from '../../assets/Group 40197.svg'
import Arrow from '../../assets/Arrow 26.svg'
import DarkArrow from '../../assets/Arrow 27.svg'

interface Topic {
    id: number,
    topic: string,
    url: string
   }
interface Product {
    id: number,
    product: string,
    url:string
}

const HelpSuggestions = () => {

    const topics : Topic[] = [
        {id: 1,    topic: 'Getting started', url: '/submit'},
        {id: 2,    topic: 'Add a Payment method', url: '/submit'},
        {id: 3,    topic: 'Managing my account', url: '/submit'},
        {id: 4,    topic: 'Fraud and spam activity', url: '/submit'},
        {id: 5,    topic: 'Privacy and security', url: '/submit'},
        {id: 6,    topic: 'Taxes', url: '/submit'},
    ];

    const products : Product[] = [
        {id: 1, product: 'Blazpay Wallet', url: '/support'},
        {id: 2, product: 'Blazpay Extension', url: '/submit'},
        {id: 3, product: 'DApp', url: '/submit'},
        {id: 4, product: 'Features', url: '/submit'},
        {id: 5, product: 'Blazpay NFTs', url: '/submit'},
        {id: 6, product: 'Token & Coin', url: '/submit'},
    ]

  return (
    <div className='lg:w-9/12 sm:w-11/12 mx-auto mt-20 mb-20'>
        <div className='flex flex-col sm:mb-10 mb-8'>
            <span className='font-Inter lg:text-[32px] sm:text-2xl lg:mb-20 sm:mb-6 mb-4 ml-3 text-xl sm:ml-5 text-white '>Help by Topics</span>
            <div className='flex lg:gap-10 sm:gap-6 gap-4 flex-wrap justify-center '>
                {
                    topics.map((topic) => (
                        <Button key={topic.id} text={topic.topic}/>
                    ))
                }
            </div>  
        </div>
        <div className='flex flex-col'>
            <span className='font-Inter lg:text-[32px] sm:text-2xl lg:mt-20 sm:mb-6 sm:ml-5 mb-4 ml-3 text-xl lg:mb-20 text-white '>Help by Products</span>
            <div className='flex lg:gap-10 sm:gap-6 gap-4 flex-wrap justify-center '>
                {
                    products.map((product) => (
                        <Button key={product.id} text={product.product}/>
                    ))
                }
            </div>  
        </div>
        <div className='flex flex-col text-white mt-5 lg:w-full sm:w-11/12 sm:mx-auto '>

            {/* {TOP ARTICLES} */}
            <span className='font-Inter lg:text-[32px] sm:text-2xl lg:mt-20 sm:mt-10 mb-8 ml-3 text-xl lg:mb-14 sm:mb-8'>Top Articles</span>

            <div className='lg:flex sm:flex justify-between lg:w-full sm:w-full mx-auto'>

                <div className='sm:text-lg lg:mb-0 sm:mb-0 mb-14'>

                    {/* {Managing my Account} */}

                    <h1 className='lg:text-2xl font-bold font-RedHat flex items-center lg:gap-8 sm:gap-2 mb-4'>Managing my account <span><img src={Vector} alt=''/></span></h1>

                    
                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-12 sm:mt-8 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>How to verify your Identity on Blazpay </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2 ' src={Arrow} alt=''/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#FF5500] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>2-step verification troubleshooting </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Submit  a Complaint </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    {/* {Crypto buy and sell} */}

                    <h1 className='lg:text-2xl font-bold font-RedHat flex items-center lg:mt-12 mt-8 mb-4 sm:mt-8 lg:gap-8 sm:gap-4'>Crypto buy and sell <span><img src={Vector} alt=''/></span></h1>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-12 sm:mt-8 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Cash out your Balance </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Why am i unable to buy Crypto? </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Blazpay Pricing and fees Disclosures </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>

                    {/* {Sending and receiving crypto} */}
                    
                </div>
                <div className='sm:text-lg'>

                    <h1 className='lg:text-2xl font-bold font-RedHat flex items-center lg:gap-8 sm:gap-2 mb-4'>Sending and receiving crypto <span><img src={Vector} alt=''/></span></h1>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-12 sm:mt-8 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Why is my Transaction Pending? </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>How to send and receive crypto  </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Assets on multiple Networks </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    {/* {Blazpay Earn} */}

                    <h1 className='lg:text-2xl font-bold font-RedHat flex items-center lg:mt-12 sm:mt-8 lg:gap-8 sm:gap-4 mt-8 mb-4'>Blazpay Earn <span><img src={Vector} alt=''/></span></h1>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-12 sm:mt-8 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Token and Region Eligible for staking </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Earn Rewards with staking </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                    <div className='lg:w-[430px] sm:w-[295px] h-8 lg:mt-5 sm:mt-2 flex justify-between sm:items-center'>
                        <Link to='/'><p className='flex items-center gap-2 lg:text-xl sm:text-xs'><span><img src={Group} alt=''/></span>Learning Rewards </p></Link>
                        <span><img className='lg:mt-3 mt-2 lg:w-3 sm:w-2' src={DarkArrow} alt='' width={12}/></span>
                    </div>
                    <div className='lg:w-[437px] sm:w-[295px] h-[1px] bg-[#7A7A7A] lg:mt-4 mt-2'></div>


                </div>
            </div>
        </div>
    </div>
  )
}

export default HelpSuggestions;