import React, { useState } from 'react';
import './ExtensionHero.css'


const ExtensionHero = () => {
    const[isActive , setIsActive] = useState('Chrome');

    function handleClick(event: React.MouseEvent<HTMLLIElement>) {
        
        const target = event.currentTarget;
        const id = target.id
        setIsActive(id)
    }

    function handlRedirect() {
        window.open('https://chromewebstore.google.com/detail/blazpay/cecndeddohekaklkfehmcnhejflmclhc', '_blank')   
    }

  return (
    <div className='bg-img-extn mx-auto h-[360px] lg:h-[873px] sm:h-[600px] flex flex-col items-center gap-10 md:gap-16 xl:gap-16'>

        <div className='lg:w-[966px] lg:mt-[265px] w-96 mt-28 sm:mt-[190px] sm:w-[580px]'>
            <h1 className='text-center font-bold lg:text-[65px] text-[24px] leading-8 sm:text-[2.4rem] sm:leading-[50px] font-Lexend lg:leading-[80px] main-text'>Download BlazPay extension 
            for your browser</h1>
        </div>


        <div className='lg:w-[331px] lg:h-[44px] w-60 relative mt-[-16px] text-white'>
            <ul className='flex justify-between font-Lexend lg:text-2xl leading-7 lg:mb-4 sm:mb-2'>
                <li className='cursor-pointer' id='Chrome' onClick={handleClick}>Chrome</li>
                <li className='cursor-pointer' id='Brave' onClick={handleClick}>Brave</li>
                <li className='cursor-pointer' id='Android' onClick={handleClick}>Android</li>
            </ul>
            <div className={` h-[2px] bg-[#FF5500] ${isActive === 'Chrome' && `lg:w-[92px] w-[60px]`} ${isActive === 'Brave' && `lg:ml-[130px] lg:w-[70px] w-[50px] ml-[95px]`} ${isActive === 'Android' && `lg:ml-[236px] lg:w-[104px] w-[70px] ml-[175px]`} absolute ease-in-out transition-all`}></div>
            <div className='lg:w-[340px] h-[1px] bg-[#494949] '></div>           
        </div>


        <div className='lg:w-[402px] lg:h-[67px] text-white mb-20'>
            <button className='lg:w-[402px] w-72 lg:h-[67px] h-12 lg:border-[4.31px] border-2 rounded-[45px] border-[#FF3503] font-RedHat font-bold lg:text-[18.66px] flex justify-center items-center box cursor-default'>
                <div className=' lg:w-[380px] rounded-md lg:h-[40px] flex items-center justify-center cursor-pointer'>
                    {isActive === 'Android' && <h1 id='Android'> Application Coming soon </h1>}
                    {isActive === 'Chrome' && <h1 id='Chrome' onClick={handlRedirect}> Install Blazpay for {isActive}</h1>}
                    {isActive === 'Brave' && <h1 id='Brave' onClick={handlRedirect}> Install Blazpay for {isActive}</h1>}
                    
                    </div></button>
        </div>
    </div>
  )
}

export default ExtensionHero;