import React from 'react';
import MobileImageFront from '../../assets/Extensions.png';
import MobileImageBack from '../../assets/Extension glow.png';

const ExtensionImage = () => {
  return (
    <div className='flex justify-center lg:mt-[-100px] mt-5 mb-[-40px] '>
        <img className='absolute lg:w-[900px]' src={MobileImageFront} alt='font' />
        <img className='relative lg:w-[900px]' src={MobileImageBack} alt='back' />
    </div>
  )
}

export default ExtensionImage;