import { ethers } from "ethers";

import zkfairStakeAbi from "./abi/zkfair/stake.json";

import katlaAbi from "./abi/taiko/mint.json";

import binanceAbi from "./abi/binance/mint.json";

import polygonAbi from "./abi/polygon/mint.json";

import victionAbi from "./abi/viction/nft.json";

export const chains = [
  // {
  //   id: "42766",
  //   name: "ZKFair Mainnet",
  //   nativeCurrency: { name: "USDC", symbol: "USDC", decimals: 18 },
  //   rpcUrls: ["https://rpc.zkfair.io"],
  //   blockExplorerUrls: ["https://explorer.katla.taiko.xyz/"],
  //   features: {
  //     spinValue: 0,
  //     mintValue: 1.5,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //     apiMinting: false,
  //   },
  //   contracts: {
  //     Entrypass: new ethers.Contract(
  //       "0x9a03A57265081ACB87a9EE66CC1D5C4F793C99D2",
  //       zkfairStakeAbi
  //     ),

  //     Mint: new ethers.Contract(
  //       "0x9a03A57265081ACB87a9EE66CC1D5C4F793C99D2",
  //       zkfairStakeAbi
  //     ),

  //     NFT: new ethers.Contract(
  //       "0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B",
  //       zkfairStakeAbi
  //     ),
  //     Stake: new ethers.Contract(
  //       "0x8A2E7143f1b153f454836c2fef5d53Fe6d8bB02B",
  //       zkfairStakeAbi
  //     ),
  //     //Store: new ethers.Contract(''),
  //   },
  // },
  // {
  //   id: '167008',
  //   name: 'Taiko Katla L2',
  //   nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
  //   rpcUrls: ['https://rpc.katla.taiko.xyz'], // replace with the actual RPC
  //   blockExplorerUrls: ['https://explorer.katla.taiko.xyz/'], // replace with the actual block explorer
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //     apiMinting: false,
  //   },
  //   contracts: {
  //     NFT: new ethers.Contract(
  //       '0x5aB420bd468BcdCa2660469cfd2AA684B6f9a0dc',
  //       katlaAbi
  //     ),
  //     Mint: new ethers.Contract(
  //       '0x5aB420bd468BcdCa2660469cfd2AA684B6f9a0dc',
  //       katlaAbi
  //     ),
  //   },
  // },
  // {
  //   id: "167009",
  //   name: "Taiko Hekla L2",
  //   nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
  //   rpcUrls: ["https://rpc.hekla.taiko.xyz"], // replace with the actual RPC
  //   blockExplorerUrls: ["https://explorer.hekla.taiko.xyz/"], // replace with the actual block explorer
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 0,
  //     unStakeValue: 0,
  //     apiStaking: false,
  //     apiMinting: false,
  //   },
  //   contracts: {
  //     NFT: new ethers.Contract(
  //       "0xbFADd5365BB2890Ad832038837115E60b71f7Cbb",
  //       katlaAbi
  //     ),
  //     Mint: new ethers.Contract(
  //       "0xbFADd5365BB2890Ad832038837115E60b71f7Cbb",
  //       katlaAbi
  //     ),
  //     DevMint: new ethers.Contract(
  //       "0x57029f14038f95704BcaB07F10f02a5e210454DC",
  //       polygonAbi
  //     ),
  //   },
  // },
  // {
  //   id: "8082",
  //   name: "	Shardeum Sphinx 1.X",
  //   nativeCurrency: {
  //     name: "SHM",
  //     symbol: "SHM",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://sphinx.shardeum.org/"],
  //   blockExplorerUrls: ["https://explorer-sphinx.shardeum.org/"],
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //     apiMinting: false,
  //   },
  //   contracts: {
  //     NFT: new ethers.Contract(
  //       "0x6cCa72D6E13179956FC31D5d6Ed4fD69a4A2a730",
  //       katlaAbi
  //     ),
  //     Mint: new ethers.Contract(
  //       "0x6cCa72D6E13179956FC31D5d6Ed4fD69a4A2a730",
  //       katlaAbi
  //     ),
  //   },
  // },
  {
    id: "56",
    name: "BNB Smart Chain Mainnet",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"],
    features: {
      spinValue: 0,
      mintValue: 0.0036,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x6D6B83c04D3b0842a432f6EEf5C66599bFa52cDC",
        polygonAbi
      ),
      Mint: new ethers.Contract(
        "0x6D6B83c04D3b0842a432f6EEf5C66599bFa52cDC",
        polygonAbi
      ),
      DevMint: new ethers.Contract(
        "0x0f9b5B509030541F6BB2aDF68a2272dC937B9577",
        polygonAbi
      ),
      MicMint: new ethers.Contract(
        "0x6784020a4c1cc9D6A59D89de265B26a2963e4308",
        polygonAbi
      ),
      NfeedMint: new ethers.Contract(
        "0x2fC519b0f8295EBAF2f0380343e7B5F21242a014",
        polygonAbi
      ),
    },
  },
  {
    id: "2818",
    name: "Morph",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-quicknode.morphl2.io"],
    blockExplorerUrls: ["https://explorer.morphl2.io/"],
    tokenImg: "https://i.ibb.co/Rv1Sc7T/morph-Logo.png",
    features: {
      spinValue: 0,
      mintValue: 0.00041,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0xbFADd5365BB2890Ad832038837115E60b71f7Cbb",
        polygonAbi
      ),
      Mint: new ethers.Contract(
        "0xbFADd5365BB2890Ad832038837115E60b71f7Cbb",
        polygonAbi
      ),
      DevMint: new ethers.Contract(
        "0x0f9b5B509030541F6BB2aDF68a2272dC937B9577",
        polygonAbi
      ),
      MicMint: new ethers.Contract(
        "0x6784020a4c1cc9D6A59D89de265B26a2963e4308",
        polygonAbi
      ),
      NfeedMint: new ethers.Contract(
        "0x2fC519b0f8295EBAF2f0380343e7B5F21242a014",
        polygonAbi
      ),
    },
  },
  {
    id: "59144",
    name: "Linea",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://linea.drpc.org"],
    blockExplorerUrls: ["https://lineascan.build/"],
    tokenImg: "https://i.ibb.co/G7yghkS/linea-Logo.png",
    features: {
      spinValue: 0,
      mintValue: 0.00041,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a",
        polygonAbi
      ),
      Mint: new ethers.Contract(
        "0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a",
        polygonAbi
      ),
      DevMint: new ethers.Contract(
        "0x0f9b5B509030541F6BB2aDF68a2272dC937B9577",
        polygonAbi
      ),
      MicMint: new ethers.Contract(
        "0x6784020a4c1cc9D6A59D89de265B26a2963e4308",
        polygonAbi
      ),
      NfeedMint: new ethers.Contract(
        "0x2fC519b0f8295EBAF2f0380343e7B5F21242a014",
        polygonAbi
      ),
    },
  },
  {
    id: "17000",
    name: "Holesky",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://ethereum-holesky-rpc.publicnode.com"],
    blockExplorerUrls: ["https://holesky.etherscan.io/"],
    tokenImg: "https://i.ibb.co/94C5X4j/ethLogo.png",
    features: {
      spinValue: 0,
      mintValue: 0.0036,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0xb5B11AA6D7866dBAF306Ca2CF4d7F77066bDE24D",
        polygonAbi
      ),
      Mint: new ethers.Contract(
        "0xb5B11AA6D7866dBAF306Ca2CF4d7F77066bDE24D",
        polygonAbi
      ),
      DevMint: new ethers.Contract(
        "0x302058AAA53F4cE391F772c3FEc807E14486574C",
        polygonAbi
      ),
    },
  },
  {
    id: "11155111",
    name: "Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://1rpc.io/sepolia"],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],
    tokenImg: "https://i.ibb.co/94C5X4j/ethLogo.png",
    features: {
      spinValue: 0,
      mintValue: 0.0036,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a",
        polygonAbi
      ),
      Mint: new ethers.Contract(
        "0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a",
        polygonAbi
      ),
      DevMint: new ethers.Contract(
        "0x302058AAA53F4cE391F772c3FEc807E14486574C",
        polygonAbi
      ),
    },
  },
  {
    id: "137",
    name: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://1rpc.io/matic"], // replace with the actual RPC
    blockExplorerUrls: ["https://polygonscan.com/"], // replace with the actual block explorer
    features: {
      spinValue: 0,
      mintValue: 1,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x73719028d10654d4CCe4F8345C84d1909c874B7b",
        polygonAbi
      ),
      Mint: new ethers.Contract(
        "0x73719028d10654d4CCe4F8345C84d1909c874B7b",
        polygonAbi
      ),
      DevMint: new ethers.Contract(
        "0x762BF38CeD664f861868770D41b60432ee587fc6",
        polygonAbi
      ),
      MicMint: new ethers.Contract(
        "0xBFDC45818d542188a1364028c2617a1B3847FEED",
        polygonAbi
      ),
      NfeedMint: new ethers.Contract(
        "0xE39FDeaD8cD8E2166C0b87996209c4C1A94A3293",
        polygonAbi
      ),
    },
  },
  // {
  //   id: "169",
  //   name: "Manta Pacific Mainnet",
  //   nativeCurrency: {
  //     name: "ETH",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://pacific-rpc.manta.network/http"],
  //   blockExplorerUrls: ["https://pacific-explorer.manta.network/"],
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0.0004,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //     apiMinting: false,
  //   },
  //   contracts: {
  //     NFT: new ethers.Contract(
  //       "0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a",
  //       katlaAbi
  //     ),
  //     Mint: new ethers.Contract(
  //       "0x427539B9f81cd890b554e4f3F36eBa6D6d96e61a",
  //       katlaAbi
  //     ),
  //   },
  // },
  // {
  //   id: "1686",
  //   name: "Mint Testnet",
  //   nativeCurrency: {
  //     name: "ETH",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://testnet-rpc.mintchain.io"],
  //   blockExplorerUrls: ["https://testnet-explorer.mintchain.io/"],
  //   features: {
  //     spinValue: 0,
  //     mintValue: 0,
  //     stakeValue: 1.5,
  //     unStakeValue: 1.5,
  //     apiStaking: false,
  //     apiMinting: false,
  //   },
  //   contracts: {
  //     NFT: new ethers.Contract(
  //       "0x778e1b518CDddC567959aB0A73EfF4D5e40b37Ca",
  //       katlaAbi
  //     ),
  //     Mint: new ethers.Contract(
  //       "0x778e1b518CDddC567959aB0A73EfF4D5e40b37Ca",
  //       katlaAbi
  //     ),
  //   },
  // },
  {
    id: "997",
    name: "5ireChain Thunder",
    nativeCurrency: {
      name: "5ire",
      symbol: "5ire",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ga.5ire.network/"],
    blockExplorerUrls: ["https://explorer.ga.5ire.network/"],
    tokenImg:
      "https://s3.coinmarketcap.com/static-gravity/image/fd7a43cc620c4ade96804bb1c36aac6f.png",
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x762BF38CeD664f861868770D41b60432ee587fc6",
        katlaAbi
      ),
      Mint: new ethers.Contract(
        "0x762BF38CeD664f861868770D41b60432ee587fc6",
        katlaAbi
      ),
    },
  },
  {
    id: "88",
    name: "Viction",
    nativeCurrency: {
      name: "VIC",
      symbol: "VIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.viction.xyz"],
    blockExplorerUrls: ["https:/vicscan.xyz"],
    tokenImg:
      "https://pbs.twimg.com/profile_images/1719637500443115520/906JpTsh_400x400.jpg",
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 1.5,
      unStakeValue: 1.5,
      apiStaking: false,
      apiMinting: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0xD524A04B30eEf8688636a14096CC7ea0bc6C3463",
        victionAbi
      ),
      Mint: new ethers.Contract(
        "0xD524A04B30eEf8688636a14096CC7ea0bc6C3463",
        victionAbi
      ),
    },
  },
  {
    id: "167000",
    name: "Taiko",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://rpc.ankr.com/taiko"], // replace with the actual RPC
    blockExplorerUrls: ["https://taikoscan.io"], // replace with the actual block explorer
    tokenImg: "https://i.ibb.co/y5D8dJv/taiko.png",
    features: {
      spinValue: 0,
      mintValue: 0.0004,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      apiMinting: false,
      gasLimit: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x60d078234bCDd1c86C5020D4a64A2213568765a4",
        katlaAbi
      ),
      Mint: new ethers.Contract(
        "0x60d078234bCDd1c86C5020D4a64A2213568765a4",
        katlaAbi
      ),
      Taiko: new ethers.Contract(
        "0x67FF7560d8fD62824B553581c590075430B9339F",
        katlaAbi
      ),
      DevMint: new ethers.Contract(
        "0x57029f14038f95704BcaB07F10f02a5e210454DC",
        polygonAbi
      ),
    },
  },
  {
    id: "30732",
    name: "Movement EVM Testnet",
    nativeCurrency: { name: "MOVE", symbol: "MOVE", decimals: 18 },
    rpcUrls: ["https://mevm.devnet.imola.movementlabs.xyz"],
    blockExplorerUrls: ["https://explorer.movementlabs.xyz"],
    tokenImg: "https://i.ibb.co/ZXGvbMx/movement-Logo.jpg",
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      apiMinting: false,
      gasLimit: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x5Bfd5504a6fc58bD957A082aBB6BB5BA65CB6eF2",
        katlaAbi
      ),
      Mint: new ethers.Contract(
        "0x5Bfd5504a6fc58bD957A082aBB6BB5BA65CB6eF2",
        katlaAbi
      ),
      Taiko: new ethers.Contract(
        "0x67FF7560d8fD62824B553581c590075430B9339F",
        katlaAbi
      ),
      DevMint: new ethers.Contract(
        "0x57029f14038f95704BcaB07F10f02a5e210454DC",
        polygonAbi
      ),
    },
  },
  {
    id: "185",
    name: "Mint Mainnet",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://rpc.mintchain.io"],
    blockExplorerUrls: ["https://explorer.mintchain.io/"],
    tokenImg:
      "https://images.mirror-media.xyz/publication-images/71MgjusontRj8AwAu7Yf-.png?height=800&width=800",
    features: {
      spinValue: 0,
      mintValue: 0.0003,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      apiMinting: false,
      gasLimit: false,
    },
    contracts: {
      NfeedMint: new ethers.Contract(
        "0xF6a53FbF7c7FB07367AEA6B54C7Cf5C94575cEba",
        polygonAbi
      ),
    },
  },
  {
    id: "1891",
    name: "Pegasus Testnet",
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://replicator.pegasus.lightlink.io/rpc/v1"],
    tokenImg: "https://i.ibb.co/7J0BZGf/lightlink-Logo.png",
    blockExplorerUrls: ["https://pegasus.lightlink.io/"],
    features: {
      spinValue: 0,
      mintValue: 0,
      stakeValue: 0,
      unStakeValue: 0,
      apiStaking: false,
      apiMinting: true,
      gasLimit: false,
    },
    contracts: {
      NFT: new ethers.Contract(
        "0x0ff2fF23d032DfdE587d3b3c5d38D3711fE9A55F",
        katlaAbi
      ),
    },
  },
];
