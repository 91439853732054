import React from 'react';
import { CiMail } from "react-icons/ci";
import './Button.css';
import { Link } from 'react-router-dom';


const SendIssueButton = () => {
  return (
    <Link to={'/submit'}><div className='lg:w-[700px] lg:h-[70px] sm:w-[400px] w-56 h-12 mx-auto btn justify-between lg:rounded-[79px] flex items-center sm:p-2 lg:p-8 md:p-4 cursor-pointer mt-[-40px] '>
        <h1 className='text-[#898989] lg:text-[30px] font-RedHat text-base ml-3 sm:text-xl lg:leading-10 lg:ml-10'>Send your Issues</h1>
        <CiMail className='lg:w-[50px] w-10 h-6 lg:h-[40px] text-[#9E9E9E] mr-2'/>
    </div></Link>
  )
}

export default SendIssueButton;