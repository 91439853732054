import React from 'react';
import ExtensionHero from '../components/extensionDownload/ExtensionHero.tsx';
import ExtensionImage from '../components/extensionDownload/ExtensionImage.tsx';
import { Footer } from '../components/homepage';

export default function Download() {
  return (
    <div>
      <ExtensionHero />
      <ExtensionImage />
      <Footer />
    </div>
  );
}
