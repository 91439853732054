import React from 'react'
import './SubmitHero.css'

const SubmitHero = () => {
  return (
    <div>
        <div className='bg-img-request lg:h-[380px] sm:h-[220px] h-[110px] flex flex-col items-center justify-center mt-10'>
            <div className='lg:w-[904px] h-20 flex items-center justify-center leading-[80px] '>
                <h1 className='font-bold lg:text-[64px] lg:leading-[80px] sm:text-4xl text-2xl  main-text font-Lexend mt-[-10px]'>Submit a Request</h1>
            </div>
        </div>
    </div>
  )
}

export default SubmitHero;