import { Navigate, createBrowserRouter } from 'react-router-dom';
import HomeLayout from '../layout/HomeLayout';
import { ElementType, Suspense, lazy } from 'react';
import ErrorPage from '../pages/error';
import { Roadmap, Team, Tokenomics } from '../components/aboutUs';
import Comingsoon from '../components/Comingsoon';
import AboutUs from '../pages/aboutUs';
import Privacy from '../pages/privacy';
import Deck from '../pages/deck';
import Download from '../pages/download';
import Support from '../pages/support';
import Submit from '../pages/submit';

const Loader = (Component) => (props) => {
  return (
    <Suspense fallback={<>Loading...</>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Routes() {
  return createBrowserRouter([
    {
      path: '/',
      element: <HomeLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: '/', element: <Homepage />, index: true },
        {
          path: 'entry-pass',
          element: <EntryPass />,
        },
        {
          path: 'about-us',
          element: <AboutUs />,
        },
        {
          path: 'roadmap',
          element: <Roadmap />,
        },
        {
          path: 'comingsoon',
          element: <Comingsoon />,
        },
        {
          path: 'entry-pass/:id',
          element: <EntryPasses />,
        },
        {
          path: '/privacy',
          element: <Privacy />,
        },
        {
          path: 'download',
          element: <Download />,
        },
        {
          path: 'support',
          element: <Support />,
        },
        {
          path: 'submit',
          element: <Submit />,
        },
      ],
    },

    // Dashboard routes : Protected
    //Test routes
    {
      path: 'deck',
      element: <Deck />,
    },
    {
      path: '/tokenomics',
      element: <Tokenomics />,
    },
    {
      path: '/team',
      element: <Team />,
    },
    // Other routes
    {
      path: '*',
      children: [
        { path: '*', element: <div className='text-white'>404</div> },
        { path: '404', element: <div className='text-white'>404</div> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
  ]);
}

const Homepage = Loader(lazy(() => import('../pages/homepage')));

const EntryPass = Loader(lazy(() => import('../pages/entry-pass')));
const EntryPasses = Loader(lazy(() => import('../pages/entry-passes')));
